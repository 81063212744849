import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import Notifications from 'vue-notification'
import CKEditor from '@ckeditor/ckeditor5-vue2';



Vue.prototype.$http = Axios;
Vue.config.productionTip = false
Vue.use(Notifications);
Vue.use( CKEditor );

require("./assets/css/style.css")

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
