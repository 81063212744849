var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pa-10"},[_c('v-col',{staticClass:"col-md-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.attributeData.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h4',[_vm._v("Attributes")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" Total : "+_vm._s(_vm.attributeData.meta ? _vm.attributeData.meta.total : 0)+" "),_c('v-spacer')],1)]},proxy:true},{key:"item.attribute_value",fn:function(ref){
var item = ref.item;
return [(item.attribute_value)?_c('div',_vm._l((item.attribute_value),function(value,key){return _c('v-chip',{key:key,staticClass:"ma-2",attrs:{"color":"green","text-color":"white","small":""}},[_vm._v(" "+_vm._s(value.name)+" ")])}),1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":_vm.editAttribute(item)}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil")]),_vm._v("  Edit ")],1)],1),_c('v-list-item',{attrs:{"to":_vm.$router.push('/attributes-listing/' + item.id)}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog")]),_vm._v("  Values ")],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getAttributesData}},[_vm._v(" Reset ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("Add new attribute")])]),_c('v-divider'),_c('v-form',{ref:"addForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitAttributeForm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-0 ml-5",attrs:{"cols":"11"}},[_c('v-alert',{staticClass:"ml-3",attrs:{"color":"info","text":""}},[_vm._v("Attributes are non deletable. You can only add or edit. ")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-3",attrs:{"label":"Enter Name","outlined":""},model:{value:(_vm.addForm.id),callback:function ($$v) {_vm.$set(_vm.addForm, "id", $$v)},expression:"addForm.id"}}),_c('v-text-field',{staticClass:"ml-3",attrs:{"label":"Enter Name","outlined":"","rules":_vm.rules.name},model:{value:(_vm.addForm.name),callback:function ($$v) {_vm.$set(_vm.addForm, "name", $$v)},expression:"addForm.name"}})],1),_c('v-col',{staticClass:"text-right mt-0",attrs:{"cols":"11"}},[_c('v-btn',{attrs:{"color":"warning","type":"submit","loading":_vm.loading,"disabled":_vm.loading,"dark":""}},[_vm._v(" Add ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }