<template>
  <div class="login">
    <v-container>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-card
            :loading="loading"
            class="mx-auto my-12"
            max-width="500"
            style="padding: 20px"
          >
            <template slot="progress">
              <v-progress-linear
                color="primary"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-card-title>
              <v-img
                :src="$store.state.logo"
                class="pa-2"
                contain
                max-width="200"
              />
            </v-card-title>

            <v-form v-model="valid" @submit.prevent="submitLogin" ref="form">
              <v-container>
                <v-col cols="12">
                  <VuePhoneNumberInput
                    v-model="mobile"
                    valid-color="#666666"
                    default-country-code="IN"
                    required
                    v-on:update="getMobileData"
                  />
                </v-col>
                <v-col cols="12" v-show="showOtp">
                  <v-otp-input
                    v-model="form.otp"
                    :disabled="loading"
                    @finish="loginUser"
                  ></v-otp-input>
                  <v-overlay absolute :value="loading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-overlay>
                </v-col>
              </v-container>

              <v-card-actions class="justify-center" v-show="!showOtp">
                <v-btn depressed class="otpBtn" @click="checkUser">
                  Check User
                </v-btn>
              </v-card-actions>

              <v-card-actions class="justify-center" v-show="showOtp">
                <v-btn depressed class="otpBtn" @click="loginUser">
                  Login
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import StoreUserAuth from "../apis/StoreUserAuth";

export default {
  name: "Login",

  components: {
    VuePhoneNumberInput,
  },

  created() {
    if (this.$store.getters.loggedIn) window.location.href = "dashboard";
  },

  data: () => ({
    showOtp: false,
    valid: false,
    passwordRules: [(v) => !!v || "Password is required"],
    loading: false,
    mobile: "",
    form: {
      mobile: "",
      country_code: "",
      otp: "",
    },
  }),

  methods: {
    getMobileData(val) {
      this.form.country_code = val.countryCallingCode;
      this.form.mobile = val.nationalNumber;
      this.mobileRule = val.isValid;
    },

    loginUser() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        StoreUserAuth.login(this.form)
          .then((response) => {
            this.loading = false;
            this.token = response.data.data.accessToken;

            var localItem = {
              access_token: this.token,
              storeUserId: response.data.data.id,
              name: response.data.data.name,
              store_id: response.data.data.store_id,
              email: response.data.data.email,
              country_code: response.data.data.country_code,
              mobile: response.data.data.mobile,
              profile: response.data.data.profile,
              type: response.data.data.type,
              created_at: response.data.data.created_at,
            };

            localStorage.setItem("storeUserLogin", JSON.stringify(localItem));
            localStorage.setItem(
              "storeInformation",
              JSON.stringify(response.data.store)
            );

            this.$store.state.token = this.token;
            this.$store.state.storeUserId = response.data.data.id;
            this.$store.state.name = response.data.data.name;
            this.$store.state.store_id = response.data.data.store_id;
            this.$store.state.email = response.data.data.email;
            this.$store.state.country_code = response.data.data.country_code;
            this.$store.state.mobile = response.data.data.mobile;
            this.$store.state.profile = response.data.data.profile;
            this.$store.state.type = response.data.data.type;
            this.$store.state.created_at = response.data.data.created_at;
            this.$store.state.storeUserLogin = JSON.stringify(localItem);
            this.$store.state.storeInformation = JSON.stringify(
              response.data.store
            );

            this.$store.state.buttonPressed = "Login";

            /* var nextUrl = this.$store.state.nextUrl;
                        this.$store.state.nextUrl = null;

                        if (nextUrl)
                            this.$router.push({name:'nextUrl'});
                        else
                            this.$router.push({name:'dashboard'}); */

            window.location.href = "/dashboard";
          })
          .catch((error) => {
            this.loading = false;

            var text = error.response.data.message;
            if (error.response.data.errors) {
              for (var value in error.response.data.errors) {
                if (text) text += "<br>";
                text += error.response.data.errors[value];
              }
            }

            this.$notify({
              title: "Error",
              text: text,
              type: "error",
              duration: 10000,
            });
          });
      }
    },

    checkUser: function () {
      this.loading = true;
      if (this.$refs.form.validate()) {
        StoreUserAuth.checkUser(this.form)
          .then(() => {
            this.loading = false;
            this.showOtp = true;
          })
          .catch((error) => {
            this.loading = false;

            var text = error.response.data.message;
            if (error.response.data.errors) {
              for (var value in error.response.data.errors) {
                if (text) text += "<br>";
                text += error.response.data.errors[value];
              }
            }

            this.$notify({
              title: "Error",
              text: text,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>
