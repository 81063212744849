<template>
    <div class="about pa-10">
        <v-col class="col-md-12">
            <v-card>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="getCategoryData" ref="form">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Search"
                                    v-model="form.search"
                                    prepend-icon="mdi-card-search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="mt-5">
                                <v-btn type="submit" small color="primary" dark> Submit </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="categoryData.data"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><h3>All Category</h3></v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-toolbar-title
                        >Total :
                            {{
                                categoryData.meta ? categoryData.meta.total : 0
                            }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn to="add-category" color="primary"> Add New Category </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:item.parent_category="{ item }">
                    {{ item.parent_category ? item.parent_category.name : "" }}
                </template>

                <template v-slot:item.banner_file="{ item }">
                    <v-img contain width="200" height="100" :src="item.banner_file.url"></v-img>
                </template>

                <template v-slot:item.featured="{ item }">
                    <v-switch
                        v-model="item.featured"
                        v-on:change="categoryStatusChange(item)"
                    ></v-switch>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-title
                                    v-on:click="$router.push('add-category/' + item.id)"
                                >
                                    <v-icon small> mdi-pencil </v-icon>&nbsp; Edit
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title v-on:click="deleteCategory(item)">
                                    <v-icon small> mdi-delete </v-icon>&nbsp; Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary"> Reset </v-btn>
                </template>
            </v-data-table>
        </v-col>
    </div>
</template>

<script>
import Category from "@/apis/Category";

export default {
    name: "CategoryView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.form.store_id = this.$store.state.store_id;
    },

    data: () => ({
        valid: false,
        modal: false,

        categoryData: [],
        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            store_id:null
        },
        options: {},
        total: 0,
        loading: true,
        headers: [
            {
                text: "Category Name",
                align: "start",
                value: "name",
            },
            { text: "Parent Category", value: "parent_category" },
            { text: "Order Level", value: "priority" },
            { text: "Banner", value: "banner_file" },
            { text: "Featured", value: "featured", sortable: false },
            { text: "Action", value: "actions", sortable: false },
        ],
    }),

    methods: {
        getCategoryData: function () {
            Category.index(this.form)
                .then((response) => {
                    this.categoryData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        categoryStatusChange: function (category) {
            Category.changeStatus(category.id)
                .then((response) => {
                    category.status = response.data.data.status;

                    this.$notify({
                        title: "Success",
                        text: "Category Information Updated !!",
                        type: "success",
                        duration: 10000,
                    });
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        deleteCategory: function (category) {
            if (confirm("Are you sure ?")) {
                Category.delete(category.id)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "Category Delete !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getCategoryData();
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getCategoryData();
            },
            deep: true,
        },
    },
};
</script>
