<template>
  <div class="about pa-10">
    <v-col class="col-md-12">
      <v-card>
        <v-card-text>
          <v-form v-model="valid" @submit.prevent="getBrandsData" ref="form">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Search"
                  v-model="form.search"
                  prepend-icon="mdi-card-search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn small color="primary" dark class="mt-5"> Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="col-md-12">
      <v-row>
        <v-col cols="12" md="7">
          <v-data-table
            :headers="headers"
            :items="brandData.data"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h4>Brands</h4> </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                Total :
                {{ brandData.meta ? brandData.meta.total : 0 }}
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.logo="{ item }">
              <v-img :src="item.logo_file.url" width="200" height="100"></v-img>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                    Actions
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title v-on:click="editBrand(item)">
                      <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title v-on:click="deleteBrand(item)">
                      <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="getBrandsData"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title><h4>Add New Brand</h4></v-card-title>
            <v-divider></v-divider>
            <v-form
              v-model="valid"
              @submit.prevent="submitBrandForm"
              ref="addForm"
            >
              <v-container>
                <v-row>
                  <v-col cols="11" class="mb-0 ml-5">
                    <v-text-field
                      class="ml-3"
                      label="Enter Name"
                      outlined
                      v-model="addForm.id"
                      v-show="false"
                    ></v-text-field>

                    <v-text-field
                      class="ml-3"
                      label="Enter Name"
                      outlined
                      v-model="addForm.name"
                      :rules="rules.name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="11" class="mb-0 ml-5">
                    <v-file-input
                      outlined
                      :rules="rules.logo"
                      v-model="files.logo"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      placeholder="Choose Brand Logo"
                      prepend-icon="mdi-camera"
                      label="Brand Logo"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="11" class="text-right mt-0">
                    <v-btn
                      color="warning"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      dark
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import Brands from "@/apis/Brands";

export default {
  name: "BrandView",

  data: () => ({
    valid: false,
    loading: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Logo", value: "logo", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    form: {
      page: 1,
      search: "",
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      store_id: "",
    },
    files: {
      logo: null,
    },

    options: {},
    total: 0,
    brandData: [],

    addForm: {
      id: "",
      name: "",
      store_id: "",
    },
    rules: {
      name: [(v) => !!v || "Brand Name is required"],
      logo: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Brand Logo size should be less than 2 MB!",
      ],
    },
  }),

  mounted() {
    this.$parent.$parent.$parent.selectedItem = 2;
    this.form.store_id = this.$store.state.store_id;
    this.addForm.store_id = this.$store.state.store_id;
  },

  methods: {
    getBrandsData() {
      Brands.index(this.form)
        .then((response) => {
          this.brandData = response.data;
          this.loading = false;
          this.total = response.data.meta.total;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    submitBrandForm() {
      if (this.$refs.addForm.validate()) {
        const fd = new FormData();

        for (var element in this.addForm) {
          fd.append(element, this.addForm[element]);
        }

        for (var fileElement in this.files) {
          fd.append(fileElement, this.files[fileElement]);
        }

        if (this.addForm.id != "") {
          fd.append("_method", "PATCH");
        }

        Brands.store(fd, this.addForm.id)
          .then(() => {
            this.getBrandsData();
            this.$refs.addForm.reset();

            this.$notify({
              title: "Success",
              text: "Brand Information Updated !!",
              type: "success",
              duration: 10000,
            });
          })
          .catch((error) => {
            this.loading = false;

            var text = error.response.data.message;
            if (error.response.data.errors) {
              for (var value in error.response.data.errors) {
                if (text) text += "<br>";
                text += error.response.data.errors[value];
              }
            }

            this.$notify({
              title: "Error",
              text: text,
              type: "error",
              duration: 10000,
            });
          });
      }
    },

    editBrand(brand) {
      this.addForm.name = brand.name;
      this.addForm.id = brand.id;
    },

    deleteBrand(brand) {
      var con = confirm("Are you sure ?");

      if (con) {
        Brands.delete(brand.id)
          .then(() => {
            this.getBrandsData();
            this.$refs.addForm.reset();

            this.$notify({
              title: "Success",
              text: "Brand Deleted !!",
              type: "success",
              duration: 10000,
            });
          })
          .catch((error) => {
            this.loading = false;

            var text = error.response.data.message;
            if (error.response.data.errors) {
              for (var value in error.response.data.errors) {
                if (text) text += "<br>";
                text += error.response.data.errors[value];
              }
            }

            this.$notify({
              title: "Error",
              text: text,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },

  watch: {
    options: {
      handler(val) {
        this.form.page = val.page;
        this.form.itemsPerPage = val.itemsPerPage;
        this.form.sortBy = val.sortBy;
        this.form.sortDesc = val.sortDesc;
        this.getBrandsData();
      },
      deep: true,
    },
  },
};
</script>