<template>
  <div class="about pa-10">
    <v-col class="col-md-12">
      <v-row>
        <v-col cols="12" md="7">
          <v-data-table
            :headers="headers"
            :items="attributeValueData.data"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h4>Attributes Values</h4></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                    Actions
                  </v-btn>
                </template>
                <v-list style="cursor: pointer">
                  <v-list-item-title v-on:click="editAttribute(item)">
                    <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                  </v-list-item-title>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="getAttributesValueData">
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title><h4>Add New Values</h4></v-card-title>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="11" class="mb-0 ml-5">
                  <v-alert class="ml-3" color="info" text
                    >Attributes are non deletable. You can only add or edit.
                  </v-alert>

                  <v-text-field
                    readonly
                    class="ml-3"
                    label="Attributes"
                    v-model="attributeData.name"
                    outlined
                    required
                  ></v-text-field>
                  <v-form
                    v-model="valid"
                    @submit.prevent="submitAttributeForm"
                    ref="addForm"
                  >
                    <v-text-field
                      class="ml-3"
                      label="Enter Name"
                      outlined
                      v-model="addForm.id"
                      v-show="false"
                    ></v-text-field>

                    <v-text-field
                      class="ml-3"
                      label="Enter Name"
                      outlined
                      v-model="addForm.name"
                      :rules="rules.name"
                    ></v-text-field>
                  </v-form>
                </v-col>

                <v-col cols="11" class="text-right mt-0">
                  <v-btn
                    color="warning"
                    type="button"
                    v-on:click="submitAttributeForm"
                    :loading="loading"
                    :disabled="loading"
                    dark
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import Attributes from "@/apis/Attributes";
import AttributeValue from "@/apis/AttributeValue";

export default {
  name: "AttributesListingView",

  data: () => ({
    valid: false,
    loading: false,
    headers: [
      {
        text: "Attribute Value",
        align: "start",
        value: "name",
      },
      { text: "Attribute", sortable: false, value: "attribute.name" },
      { text: "Actions", value: "actions" },
    ],
    form: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      attribute_id: "",
    },
    options: {},
    total: 0,
    attributeData: [],
    attributeValueData: [],

    addForm: {
      id: "",
      attribute_id: "",
      name: "",
    },
    rules: {
      name: [(v) => !!v || "Attribute Name is required"],
    },
  }),

  mounted() {
    this.$parent.$parent.$parent.selectedItem = 2;
    this.addForm.attribute_id = this.$route.params.id;
    this.form.attribute_id = this.$route.params.id;
    this.getAttributesData();
  },

  methods: {
    getAttributesValueData() {
      AttributeValue.index(this.form)
        .then((response) => {
          this.attributeValueData = response.data;
          this.loading = false;
          this.total = response.data.meta.total;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getAttributesData() {
      Attributes.show(this.addForm.attribute_id)
        .then((response) => {
          this.attributeData = response.data.data;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    submitAttributeForm() {
      if (this.$refs.addForm.validate()) {
        if (this.addForm.id) {
          AttributeValue.update(this.addForm, this.addForm.id)
            .then(() => {
              this.getAttributesValueData();
              this.$refs.addForm.reset();
              this.$notify({
                title: "Success",
                text: "Attribute Information Updated !!",
                type: "success",
                duration: 10000,
              });
            })
            .catch((error) => {
              this.loading = false;

              var text = error.response.data.message;
              if (error.response.data.errors) {
                for (var value in error.response.data.errors) {
                  if (text) text += "<br>";
                  text += error.response.data.errors[value];
                }
              }

              this.$notify({
                title: "Error",
                text: text,
                type: "error",
                duration: 10000,
              });
            });
        } else {
          AttributeValue.store(this.addForm)
            .then(() => {
              this.getAttributesValueData();
              this.$refs.addForm.reset();
              this.$notify({
                title: "Success",
                text: "Attribute Information Updated !!",
                type: "success",
                duration: 10000,
              });
            })
            .catch((error) => {
              this.loading = false;

              var text = error.response.data.message;
              if (error.response.data.errors) {
                for (var value in error.response.data.errors) {
                  if (text) text += "<br>";
                  text += error.response.data.errors[value];
                }
              }

              this.$notify({
                title: "Error",
                text: text,
                type: "error",
                duration: 10000,
              });
            });
        }
      }
    },

    editAttribute(attribute) {
      this.addForm.name = attribute.name;
      this.addForm.id = attribute.id;
    },
  },

  watch: {
    options: {
      handler(val) {
        this.form.page = val.page;
        this.form.itemsPerPage = val.itemsPerPage;
        this.form.sortBy = val.sortBy;
        this.form.sortDesc = val.sortDesc;
        this.getAttributesValueData();
      },
      deep: true,
    },
  },
};
</script>