<template>
    <div class="about pa-10">
        <v-col class="col-md-12">
            <v-card>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="getProductData" ref="form">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Search"
                                    v-model="form.search"
                                    prepend-icon="mdi-card-search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="mt-5">
                                <v-btn type="submit" small color="primary" dark> Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="productData.data"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><h4>All products</h4></v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>
                        <v-btn to="add-product" color="primary"> Add New Product</v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:item.product_name="{ item }">
                    <v-img contain width="80" height="80" :src="item.thumbnail_file.url">
                    </v-img>
                    {{ item.product_name }}
                </template>

                <template v-slot:item.info="{ item }">
                    <strong>Unit:</strong>&nbsp;{{ item.product_unit }}
                </template>

                <template v-slot:item.product_category="{ item }">
                    <div v-if="item.product_category">
                        <v-chip
                            v-for="(value, key) in item.product_category"
                            :key="key"
                            class="ma-2"
                            color="green"
                            text-color="white"
                            small
                        >
                            {{ value.category.name }}
                        </v-chip>
                    </div>
                </template>

                <template v-slot:item.brand="{ item }">
                    <div v-if="item.brand">
                        <v-img contain width="80" height="80"
                            :src="item.brand.logo_file.url"
                        ></v-img>
                    </div>
                </template>

                <template v-slot:item.product_status="{ item }">
                    <v-switch
                        v-model="item.product_status"
                        false-value="Draft"
                        true-value="Published"
                        v-on:change="productStatusChange(item)"
                    ></v-switch>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item style="cursor: pointer">
                                <v-list-item-title
                                    v-on:click="$router.push('product-view/' + item.id)"
                                >
                                    <v-icon small> mdi-eye</v-icon>&nbsp; View
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item style="cursor: pointer">
                                <v-list-item-title
                                    v-on:click="$router.push('add-product/' + item.id)"
                                >
                                    <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item style="cursor: pointer">
                                <v-list-item-title v-on:click="deleteProduct(item)">
                                    <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                                </v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item style="cursor: pointer">
                              <v-list-item-title
                                v-on:click="$router.push('add-product/' + item.id)"
                              >
                                <v-icon small> mdi-copy</v-icon>&nbsp; Duplicate
                              </v-list-item-title>
                            </v-list-item> -->
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary"> Reset</v-btn>
                </template>
            </v-data-table>
        </v-col>
    </div>
</template>
<script>
import Product from "@/apis/Product";

export default {
    name: "ProductListView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.form.store_id = this.$store.state.store_id;
    },

    data: () => ({
        valid: false,
        loading: true,
        productData: [],
        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            store_id:null
        },
        options: {},
        total: 0,
        headers: [
            {
                text: " Name",
                align: "start",
                value: "product_name",
                sortable: false,
            },
            { text: "Info", value: "info", sortable: false },
            { text: "Categories", value: "product_category", sortable: false },
            { text: "Brand", value: "brand", sortable: false },
            { text: "Published", value: "product_status", sortable: false },
            { text: "Action", value: "actions", sortable: false },
        ],
    }),

    methods: {
        getProductData: function () {
            Product.index(this.form)
                .then((response) => {
                    this.productData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        productStatusChange: function (product) {
            Product.changeStatus(product.id)
                .then((response) => {
                    product.status = response.data.data.status;

                    this.$notify({
                        title: "Success",
                        text: "Product Information Updated !!",
                        type: "success",
                        duration: 10000,
                    });
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        deleteProduct: function (product) {
            if (confirm("Are you sure ?")) {
                Product.delete(product.id)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "Product Deleted !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getProductData();
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getProductData();
            },
            deep: true,
        },
    },
};
</script>
