import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Orders from "../views/order_view/Orders";
import ProductsListView from "../views/product_view/ProductsListView";
import CategoryView from "../views/product_view/CategoryView";
import BrandView from "../views/product_view/BrandView";
import AttributesView from "../views/product_view/AttributesView";
import CustomerView from "../views/customer_view/CustomerView";
import AnalyticsView from "../views/analytics_view/AnalyticsView";
import RevenueView from "../views/revenuew_view/RevenueView";
import UploadedFilesView from "../views/uploaded_files_view/UploadedFilesView";
import AddCategoryView from "../views/product_view/AddCategoryView";
import AttributesListingView from "../views/product_view/AttributesListingView";
import AddProductView from "../views/product_view/AddProductView";
import ProductsView from "../views/product_view/ProductsView";
import OrdersView from "../views/order_view/OrdersView";
import CustomerListView from "../views/customer_view/CustomerListView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: { guestOnly: true },
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { authOnly: true }
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: { authOnly: true }
  },
  {
    path: '/orders-view/:id',
    name: 'orders-view',
    component: OrdersView,
    meta: { authOnly: true }
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsListView,
    meta: { authOnly: true }
  },
  {
    path: '/add-product/:id?',
    name: 'add-product',
    component: AddProductView,
    meta: { authOnly: true }
  },
  {
    path: '/product-view/:id',
    name: 'product-view',
    component: ProductsView,
    meta: { authOnly: true }
  },
  {
    path: '/category',
    name: 'category',
    component: CategoryView,
    meta: { authOnly: true }
  },
  {
    path: '/add-category/:id?',
    name: 'add-category',
    component: AddCategoryView,
    meta: { authOnly: true }
  },
  {
    path: '/brand',
    name: 'brand',
    component: BrandView,
    meta: { authOnly: true }
  },
  {
    path: '/attributes',
    name: 'attributes',
    component: AttributesView,
    meta: { authOnly: true }
  },
  {
    path: '/attributes-listing/:id',
    name: 'attributes-listing',
    component: AttributesListingView,
    meta: { authOnly: true }
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomerListView,
    meta: { authOnly: true }
  },
  {
    path: '/customer-view/:id',
    name: 'customer-view',
    component: CustomerView,
    meta: { authOnly: true }
  },

  {
    path: '/analytics',
    name: 'analytics',
    component: AnalyticsView,
    meta: { authOnly: true }
  },
  {
    path: '/revenue',
    name: 'revenue',
    component: RevenueView,
    meta: { authOnly: true }
  },
  {
    path: '/uploaded-files',
    name: 'uploaded-files',
    component: UploadedFilesView,
    meta: { authOnly: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isLoggedIn() {
  return localStorage.getItem("storeUserLogin");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router
