import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        logo: (localStorage.getItem("storeInformation")) ? JSON.parse(localStorage.getItem("storeInformation")).logo_url : require('../assets/images/logo.png'),
        appName: 'EssenMart',

        baseUrl: (process.env.NODE_ENV === 'production') ? "https://api.essenmart.com" : "http://localhost:8000/",

        storeUserLogin: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")) : null,
        storeUserId: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).storeUserId : null,
        name: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).name : null,
        store_id: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).store_id : null,
        email: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).email : null,
        country_code: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).country_code : null,
        mobile: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).mobile : null,
        profile: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).profile : null,
        type: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).type : null,
        created_at: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).created_at : null,
        token: (localStorage.getItem("storeUserLogin")) ? JSON.parse(localStorage.getItem("storeUserLogin")).access_token : null,
        storeInformation: (localStorage.getItem("storeInformation")) ? JSON.parse(localStorage.getItem("storeInformation")) : null,

        nextUrl: null,
    },

    getters: {
        loggedIn: state => {
            return state.token !== null
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})
