import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/order-product", {
            params: form
        });
    },

    async store(form) {
        return Api.post("/order-product" , form);
    },

    async show(id) {
        return Api.post("/order-product/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/order-product/" + id, form);
    },

    async delete(id) {
        return Api.delete("/order-product/" + id);
    },
};
