<template>
    <v-app>
        <v-navigation-drawer
            app
            light
            v-if="this.$store.getters.loggedIn"
            clipped
            permanent
        >
            <v-list shaped>
                <v-list-item-group v-model="selectedItem" color="primary">
                    <div v-for="(item, i) in items" :key="i">
                        <v-list-item
                            v-if="!item.subLinks"
                            v-on:click="$router.push({ name: item.link })"
                        >
                            <v-list-item-icon>
                                <v-icon class="nav-text" v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="nav-text"
                                    v-text="item.text"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-group
                            v-else
                            no-action
                            :prepend-icon="item.icon"
                            color="primary"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </template>

                            <v-list-item
                                v-for="(subitem, i) in item.subLinks"
                                :key="i"
                                link
                                v-on:click="$router.push({ name: subitem.link })"
                            >
                                <!-- <v-list-item-icon>
                                  <v-icon class="nav-text" v-text="subitem.icon"></v-icon>
                                </v-list-item-icon> -->
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="nav-text"
                                        v-text="subitem.text"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </div>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar fixed clipped-left app light v-if="this.$store.getters.loggedIn">
            <div class="d-flex align-center">
                <v-img
                    :alt="$store.state.appName"
                    class="shrink mr-2"
                    contain
                    :src="$store.state.logo"
                    transition="scale-transition"
                    width="230"
                />
            </div>

            <v-divider class="mr-5" vertical></v-divider>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="mr-5"
                    >
                        <v-icon>mdi-plus</v-icon>&nbsp; Add New
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item link :to="'add-product'">
                        <v-list-item-title>Add Product</v-list-item-title>
                    </v-list-item>
                    <v-list-item link :to="'add-category'">
                        <v-list-item-title>Add Category</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

<!--            <v-autocomplete
                chips
                clearable
                hide-details
                hide-selected
                item-text="name"
                item-value="symbol"
                label="Search anything here..."
                solo
            >
                <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-title>
                            Search for
                            <strong>Orders, Products, Stores, Customer</strong>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>-->

            <v-spacer></v-spacer>

            <v-menu bottom min-width="200px" rounded offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <v-avatar color="blue" size="36">
                            <span class="white--text">{{ user.initials }}</span>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <v-avatar color="blue" size="36px" class="mb-5">
                                <span class="white--text">{{ user.initials }}</span>
                            </v-avatar>
                            <h3>{{ user.fullName }}</h3>
                            <p class="text-caption mt-1">
                                {{ user.email }}
                            </p>
                            <v-divider class="my-3"></v-divider>
                            <v-btn depressed rounded text> Edit Account</v-btn>
                            <v-divider class="my-3"></v-divider>
                            <v-btn depressed rounded text v-on:click="logout"> Logout</v-btn>
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>
        <notifications position="top center" width="500" closeOnClick/>
    </v-app>
</template>

<script>
import StoreUserAuth from "@/apis/StoreUserAuth";

export default {
    name: "App",

    mounted() {
        this.speakerInitials();
        this.user.fullName = this.$store.state.name;
        this.user.email = this.$store.state.email;
    },

    data: () => ({
        drawer: false,
        group: null,
        user: {
            initials: "",
            fullName: "",
            email: "",
        },
        selectedItem: 0,
        subSelectedItem: 0,
        items: [
            {text: "Dashboard", icon: "mdi-view-dashboard", link: "dashboard"},
            {text: "Orders", icon: "mdi-cart", link: "orders"},
            {
                text: "Product",
                icon: "mdi-flag",
                subLinks: [
                    {
                        text: "Products",
                        icon: "mdi-flag",
                        link: "products",
                    },
                    {
                        text: "Category",
                        icon: "mdi-flag",
                        link: "category",
                    },
                    {
                        text: "Brand",
                        icon: "mdi-flag",
                        link: "brand",
                    },
                    {
                        text: "Attributes",
                        icon: "mdi-flag",
                        link: "attributes",
                    },
                ],
            },
            {text: "Customers", icon: "mdi-account-group", link: "customers"},
            // { text: "Analytics", icon: "mdi-google-analytics", link: "analytics" },
            {text: "Revenue", icon: "mdi-wallet", link: "revenue"},
            // {text: "Uploaded Files", icon: "mdi-file", link: "uploaded-files"},
        ],
    }),

    methods: {
        speakerInitials() {
            var speaker = this.$store.state.name;

            if (speaker) {
                const name = speaker.split(" ");
                this.user.initials = `${name[0].charAt(0)}${
                    name[1] ? name[1].charAt(0) : ""
                }`;
            }
        },

        logout() {
            StoreUserAuth.logout().then(() => {
                localStorage.removeItem("storeUserLogin");
                localStorage.removeItem("storeInformation");
                this.$router.push("/");
            });
        },
    },

    watch: {
        group() {
            this.drawer = false;
        },
    },
};
</script>
