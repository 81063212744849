<template>
    <div class="about pa-10">
        <v-row>
            <v-col cols="3">
                <v-card>
                    <h2 class="text-center pa-5">
                        <v-avatar color="orange" size="150">
                            <img
                                v-if="customerData.image_file"
                                :src="customerData.image_file.url"
                            />
                            <v-icon v-else size="150" dark> mdi-account-circle</v-icon>
                        </v-avatar>

                        <br/><br/>
                        {{ customerData.name }}
                    </h2>
                    <v-divider></v-divider>
                    <div class="text-left ma-5 pr-5 pl-5">
                        <h3 class="mb-4 text-left">
                            <span class="bg-white pr-3">Account Information</span>
                        </h3>

                        <p class="text-muted">
                            <strong>Full Name :</strong>
                            <span class="ml-2">{{ customerData.name }}</span>
                        </p>
                        <p class="text-muted">
                            <strong>Email :</strong>
                            <span class="ml-2"> {{ customerData.email }}</span>
                        </p>
                        <p class="text-muted">
                            <strong>Phone :</strong>
                            <span class="ml-2">
                {{
                                    customerData.country_code + " " + customerData.mobile
                                }}</span
                            >
                        </p>
                        <p class="text-muted">
                            <strong>Registration Date :</strong>
                            <span class="ml-2"> {{ customerData.created_at }}</span>
                        </p>
                        <!-- <p class="text-muted">
                          <strong>Balance :</strong>
                          <span class="ml-2"> {{ customerData.name }}</span>
                        </p> -->
                    </div>
                    <div class="ma-5 pl-5 pr-5 pb-5">
                        <h3 class="separator mb-4 text-left">
                            <span class="bg-white pr-3">Others Information </span>
                        </h3>
                        <p class="text-muted">
                            <strong>Number of Orders :</strong>
                            <span class="ml-2">{{ customerData.order_count }}</span>
                        </p>
                        <!-- TODO : Update Below Detils -->
                        <p class="text-muted">
                            <strong>Ordered Amount :</strong>
                            <span class="ml-2">₹ {{ customerData.order_amount }}</span>
                        </p>
<!--                        <p class="text-muted">
                            <strong>Number of items in cart :</strong>
                            <span class="ml-2">{{ customerData.order_count }}</span>
                        </p>-->
<!--                        <p class="text-muted">
                            <strong>Number of items in wishlist :</strong>
                            <span class="ml-2">{{ customerData.order_count }}</span>
                        </p>-->
<!--                        <p class="text-muted">
                            <strong>Total reviewed products :</strong>
                            <span class="ml-2">{{ customerData.order_count }}</span>
                        </p>-->
                    </div>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-col class="col-md-12">
                    <v-data-table
                        :headers="headers"
                        :items="orderData.data"
                        :loading="loading"
                        :options.sync="options"
                        :server-items-length="total"
                        class="elevation-1"
                    >
                        <template v-slot:top>
                            <v-toolbar
                                flat
                            >
                                <v-toolbar-title><h4>Orders</h4></v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                Total : {{ total }}
                                <v-spacer></v-spacer>

                            </v-toolbar>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                        Actions
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item link :to="'/orders-view/' + item.id">
                                        <v-list-item-title>
                                            <v-icon small> mdi-eye</v-icon>&nbsp; View
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item link>
                                        <v-list-item-title v-on:click="deleteOrder(item)">
                                            <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:no-data>
                            <v-btn
                                color="primary"
                            >
                                Reset
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-col>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Customer from "@/apis/Customer";
import Order from "@/apis/Order";

export default {
    name: "CustomerView",

    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'Customer Name',
                align: 'start',
                sortable: false,
                value: 'customer.name',
            },
            {text: 'Email address', value: 'customer.email'},
            {text: 'Store Name', value: 'store.store_name'},
            {text: 'Phone', value: 'customer.mobile'},
            {text: 'Payment Status', value: 'payment_status'},
            {text: 'Delivery Status', value: 'delivery_status'},
            {text: 'Order Code', value: 'order_code'},
            {text: 'Price', value: 'price'},
            {text: 'Options', value: 'actions', sortable: false},
        ],

        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            customer_id: "",
        },

        options: {},
        total: 0,
        orderData: [],
        order_count: {},
        customerData: [],
    }),

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 3;
        this.form.customer_id = this.$route.params.id;
        this.getCustomerData();

        this.getOrderData();
        this.getOrderCount();
    },

    methods: {
        getCustomerData() {
            Customer.show(this.form.customer_id)
                .then((response) => {
                    this.customerData = response.data.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getOrderData() {
            Order.index(this.form)
                .then((response) => {
                    this.orderData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getOrderCount() {
            Order.order_count(this.form)
                .then((response) => {
                    this.order_count = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        deleteOrder(order) {
            var conf = confirm("Are you sure ?");

            if (conf) {
                Order.delete(order.id)
                    .then(() => {
                        this.getOrderData();

                        this.$notify({
                            title: "Success",
                            text: "Order Deleted Successfully !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
            }
        }
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getOrderData();
            },
            deep: true,
        },
    },
};
</script>
