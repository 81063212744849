<template>
  <div class="about pa-10">
    <v-col class="col-md-12 pa-5">
      <v-form v-model="valid" @submit.prevent="submitProductForm" ref="form">
        <v-row>
          <v-col col="12" md="8">
            <!--          first -->
            <v-card class="mb-4" :loading="loading">
              <v-card-title class="ml-5">Product Information</v-card-title>
              <v-divider></v-divider>

              <v-container fluid>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <v-text-field
                      class="ml-3"
                      label="Product Name"
                      outlined
                      v-model="form.product_name"
                    ></v-text-field>
                    <v-text-field
                      class="ml-3"
                      label="Minimum Purchase Qty"
                      outlined
                      v-model="form.minimum_purchase"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      class="ml-3"
                      label="Unit (e.g. 500 Gram, 2 Litre, 5 Pc etc)"
                      outlined
                      v-model="form.product_unit"
                    ></v-text-field>

                    <v-text-field
                      class="ml-3"
                      label="Maximum Purchase Qty"
                      v-model="form.maximum_purchase"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!--          second card-->
            <v-card class="mb-4">
              <v-card-title class="ml-5">Product Images</v-card-title>
              <v-divider></v-divider>

              <v-container fluid>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <v-file-input
                      show-size
                      counter
                      outlined
                      label="Thumbnail Image (300x300)"
                      v-model="files.thumbnail"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6">
                    <v-file-input
                      outlined
                      show-size
                      counter
                      multiple
                      label="Gallery Images(600x600)"
                      v-model="files.images"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!--          third card-->
            <v-card class="mb-4">
              <v-col cols="12">
                <v-row class="ml-2">
                  <v-col cols="8">
                    <v-card-title>Product price, stock</v-card-title>
                  </v-col>
                  <v-col cols="4">
                    <v-sheet>
                      <v-switch
                        v-model="form.variant"
                        inset
                        false-value="N"
                        true-value="Y"
                        label="Variant Product"
                      ></v-switch>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider></v-divider>

              <v-container fluid>
                <div v-show="form.variant == 'N'">
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.price"
                        label="Regular price "
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        label="SKU"
                        v-model="form.sku"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :items="stockOption"
                        item-text="option"
                        item-value="option"
                        label="Stock Status"
                        v-model="form.status"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="form.variant == 'Y'">
                  <v-row class="ma-2">
                    <v-col cols="12">
                      <v-alert color="info" text
                        >Select an option for this product and then select
                        choices of each option.
                      </v-alert>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :items="attributeOption"
                        item-text="name"
                        item-value="id"
                        label="Select an Attribute"
                        v-model="select.attribute"
                        return-object
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-select
                        :items="attributeValueOption"
                        item-text="name"
                        item-value="id"
                        label="Select an Attribute Value"
                        v-model="select.attribute_value"
                        return-object
                        outlined
                        multiple
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="1" class="mt-2">
                      <v-fab-transition>
                        <v-btn v-show="!hidden" color="grey" dark>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </v-col> -->
                    <v-row>
                      <v-col cols="2">
                        <v-card-text>Variant</v-card-text>
                      </v-col>
                      <v-col cols="3">
                        <v-card-text>Variant Price</v-card-text>
                      </v-col>
                      <v-col cols="2">
                        <v-card-text>Stock</v-card-text>
                      </v-col>
                      <v-col cols="3">
                        <v-card-text>SKU</v-card-text>
                      </v-col>
                      <v-col cols="2">
                        <v-card-text>Image</v-card-text>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(attribute_value, key) in select.attribute_value"
                      :key="key"
                    >
                      <v-col cols="2">
                        <v-card-text
                          >{{ select.attribute.name }}:
                          {{ attribute_value.name }}</v-card-text
                        >
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="form.variantForm.price[key]"
                          label="Price"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          :items="stockOption"
                          v-model="form.variantForm.status[key]"
                          item-text="option"
                          item-value="option"
                          label="Stock Status"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="form.variantForm.sku[key]"
                          label="SKU"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-file-input
                          v-model="variantFile.image[key]"
                          label="Image"
                          outlined
                        ></v-file-input>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </v-container>
            </v-card>
            <!--          fourth Card-->
            <!-- <v-card class="mb-4">
            <v-col cols="12">
              <v-card-title class="ml-5">Product Discount</v-card-title>
            </v-col>
            <v-divider></v-divider>

            <v-container fluid>
              <v-row class="ma-2">
                <v-col cols="3">
                  <div class="">
                    <v-card-text>Discount Date Range</v-card-text>
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-text-field label="Select date" outlined></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-card-text>Discount *</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="0" outlined></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select :items="items" label="Flat" outlined></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card> -->
            <!--          card 5-->
            <v-card class="mb-4">
              <v-card-title class="ml-5">Shipping Information</v-card-title>

              <v-divider></v-divider>

              <v-container fluid>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <v-text-field
                      label="Standard delivery time"
                      outlined
                      v-model="form.standard_delivery"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Express delivery time"
                      outlined
                      v-model="form.express_delivery"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <v-text-field
                      v-model="form.weight"
                      label="Weight"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Height"
                      v-model="form.height"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                  <v-col cols="6">
                    <v-text-field
                      label="Length"
                      v-model="form.length"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Width"
                      v-model="form.width"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!--          card 6-->
            <v-card class="mb-4">
              <v-card-title class="ml-5">Product Description</v-card-title>

              <v-divider></v-divider>

              <v-container fluid>
                <v-row class="ma-2">
                  <v-col cols="3">
                    <div class="">
                      <v-card-text>Description</v-card-text>
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <!-- <v-textarea outlined label="Product Description"></v-textarea> -->
                    <ckeditor
                      :editor="editor"
                      v-model="form.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>

          <v-col col="12" md="4">
            <v-card>
              <v-card-title>Product Status</v-card-title>
              <v-divider></v-divider>
              <v-row align="center" class="ma-5">
                <v-select
                  :items="productOption"
                  v-model="form.product_status"
                  item-text="option"
                  item-value="option"
                  label="Product Status"
                  outlined
                ></v-select>
              </v-row>
            </v-card>
            <v-card>
              <v-card-title>Product Brand</v-card-title>
              <v-divider></v-divider>
              <v-row align="center" class="ma-5">
                <v-select
                  :items="brandOption"
                  v-model="form.brand_id"
                  item-text="name"
                  item-value="id"
                  label="Brand"
                  outlined
                ></v-select>
              </v-row>
            </v-card>
            <v-card>
              <v-card-title>Product Category</v-card-title>
              <v-divider></v-divider>
              <v-row align="center" class="ma-5">
                <v-treeview
                  open-all
                  :selection-type="'independent'"
                  v-model="form.product_category"
                  selectable
                  :items="categoryOption"
                ></v-treeview>
              </v-row>
            </v-card>
            <v-card>
              <v-card-title>Product Tags</v-card-title>
              <v-divider></v-divider>
              <v-row align="center" class="ma-5">
                <v-combobox
                  v-model="form.product_tags"
                  hide-selected
                  hint="Type and hit enter to add a tag"
                  label="Product Tags"
                  multiple
                  small-chips
                  outlined
                >
                </v-combobox>
              </v-row>
            </v-card>
            <v-card>
              <v-card-title>Tax Information</v-card-title>
              <v-divider></v-divider>

              <v-row align="center" class="ma-5">
                <!-- <v-col cols="12">
                <h4>VAT</h4>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="0" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  class="ml-3"
                  :items="items"
                  label="Flat"
                  outlined
                ></v-select>
              </v-col> -->
                <v-col cols="12">
                  <h4>GST</h4>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Tax"
                    v-model="form.taxForm.gst"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :items="taxOption"
                    v-model="form.taxForm.gst_type"
                    item-text="option"
                    item-value="option"
                    label="Tax Type"
                    outlined
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12">
                <h4>TAX</h4>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field label="0" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  class="ml-3"
                  :items="items"
                  label="Flat"
                  outlined
                ></v-select>
              </v-col> -->
              </v-row>
            </v-card>

            <!--          card 8-->
            <v-card class="mb-4">
              <v-card-title class="ml-5">SEO Meta Tags</v-card-title>

              <v-divider></v-divider>

              <v-container fluid>
                <v-col class="ma-2">
                  <v-text-field
                    label="Meta title"
                    v-model="form.meta_title"
                    outlined
                  ></v-text-field>
                  <v-textarea
                    outlined
                    v-model="form.meta_description"
                    label="Meta Description"
                  ></v-textarea>
                  <v-file-input
                    outlined
                    v-model="files.meta_image"
                    label="Meta Image"
                  ></v-file-input>
                </v-col>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn type="submit" dark color="orange" :loading="loading" large
            >Upload Product</v-btn
          >
        </div>
      </v-form>
    </v-col>
  </div>
</template>

<script>
import Attribute from "@/apis/Attributes";
import Brands from "@/apis/Brands";
import Category from "@/apis/Category";
import Product from "@/apis/Product";
import ProductVariant from "@/apis/ProductVariant";
import ProductTax from "@/apis/ProductTax";
import ProductTag from "@/apis/ProductTag";
import ProductImage from "@/apis/ProductImage";
import ProductCategory from "@/apis/ProductCategory";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "AddProductView",

  created() {
    this.$parent.$parent.$parent.selectedItem = 2;
  },

  async mounted() {
    this.form.store_id = this.$store.state.store_id;
    this.searchForm.store_id = this.$store.state.store_id;
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;

      this.getProductDetail();
    }

    await this.getAttributeData();
    await this.getBrandData();
    await this.getCategoryData();
  },

  data: () => ({
    valid: false,
    loading: false,

    form: {
      id: "",
      store_id: "",
      product_name: "",
      product_unit: "",
      minimum_purchase: "",
      maximum_purchase: "",
      variant: "N",
      price: "",
      status: "",
      sku: "",
      product_status: "",
      brand_id: "",
      standard_delivery: "",
      express_delivery: "",
      weight: "",
      height: "",
      length: "",
      width: "",
      description: "",
      meta_title: "",
      meta_description: "",
      product_category: [],
      product_tags: [],

      variantForm: {
        id: [],
        attribute_id: "",
        attribute_value_id: [],
        price: [],
        status: [],
        sku: [],
      },

      taxForm: {
        id: "",
        vat: "",
        vat_type: "",
        gst: "",
        gst_type: "",
        tax: "",
        tax_type: "",
      },
    },

    variantFile: {
      image: [],
    },
    files: {
      thumbnail: null,
      images: [],
      meta_image: null,
    },

    //TODO : Create Rules for Form Submission

    select: {
      attribute: {},
      attribute_value: [],
    },

    stockOption: [{ option: "In Stock" }, { option: "Out of Stock" }],
    productOption: [{ option: "Published" }, { option: "Draft" }],
    taxOption: [{ option: "Flat" }, { option: "Percent" }],
    attributeOption: [],
    attributeValueOption: [],
    brandOption: [],
    categoryOption: [],
    storeOption: [],

    editor: ClassicEditor,
    editorConfig: {
      toolbarGroups: [
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
          name: "paragraph",
          groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
        },
        { name: "links", groups: ["links"] },
      ],
    },

    productData: {},

    searchForm: {
      store_id: null,
    },
  }),

  methods: {
    getAttributeData: async function () {
      return Attribute.index(this.searchForm)
        .then((response) => {
          this.attributeOption = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getBrandData: async function () {
      return Brands.index(this.searchForm)
        .then((response) => {
          this.brandOption = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getCategoryData: async function () {
      return Category.get_tree(this.searchForm)
        .then((response) => {
          this.categoryOption = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getProductDetail: function () {
      Product.show(this.form.id)
        .then((response) => {
          this.productData = response.data.data;

          this.form.store_id = this.productData.store_id;
          this.form.product_name = this.productData.product_name;
          this.form.product_unit = this.productData.product_unit;
          this.form.minimum_purchase = this.productData.minimum_purchase;
          this.form.maximum_purchase = this.productData.maximum_purchase;
          this.form.variant = this.productData.variant;
          this.form.price = this.productData.price;
          this.form.status = this.productData.status;
          this.form.sku = this.productData.sku;
          this.form.product_status = this.productData.product_status;
          this.form.brand_id = this.productData.brand_id;
          this.form.standard_delivery = this.productData.standard_delivery;
          this.form.express_delivery = this.productData.express_delivery;
          this.form.weight = this.productData.weight;
          this.form.height = this.productData.height;
          this.form.length = this.productData.length;
          this.form.width = this.productData.width;
          this.form.description = this.productData.description;
          this.form.meta_title = this.productData.meta_title;
          this.form.meta_description = this.productData.meta_description;

          for (var product_category in this.productData.product_category) {
            this.form.product_category[product_category] =
              this.productData.product_category[product_category].category_id;
          }

          for (var product_tags in this.productData.product_tags) {
            this.form.product_tags[product_tags] =
              this.productData.product_tags[product_tags].tags;
          }

          this.form.taxForm.id = this.productData.product_tax.id;
          this.form.taxForm.vat = this.productData.product_tax.vat;
          this.form.taxForm.vat_type = this.productData.product_tax.vat_type;
          this.form.taxForm.gst = this.productData.product_tax.gst;
          this.form.taxForm.gst_type = this.productData.product_tax.gst_type;
          this.form.taxForm.tax = this.productData.product_tax.tax;
          this.form.taxForm.tax_type = this.productData.product_tax.tax_type;

          if (this.productData.variant == "Y") {
            for (var product_variant in this.productData.product_variant) {
              this.select.attribute =
                this.productData.product_variant[product_variant].attribute;

              this.attributeValueOption = this.select.attribute.attribute_value;

              this.select.attribute_value[product_variant] =
                this.productData.product_variant[
                  product_variant
                ].attribute_value;

              this.form.variantForm.attribute_id =
                this.productData.product_variant[product_variant].attribute_id;

              this.form.variantForm.id[product_variant] =
                this.productData.product_variant[product_variant].id;

              this.form.variantForm.attribute_value_id[product_variant] =
                this.productData.product_variant[
                  product_variant
                ].attribute_value_id;

              this.form.variantForm.price[product_variant] =
                this.productData.product_variant[product_variant].price;
              this.form.variantForm.status[product_variant] =
                this.productData.product_variant[product_variant].status;
              this.form.variantForm.sku[product_variant] =
                this.productData.product_variant[product_variant].sku;
            }
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    submitProductForm: function () {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fd = new FormData();

        for (var element in this.form) {
          fd.append(element, this.form[element]);
        }

        for (var fileElement in this.files) {
          fd.append(fileElement, this.files[fileElement]);
        }

        if (this.form.id) {
          fd.append("_method", "PATCH");
        }

        Product.store(fd, this.form.id)
          .then((response) => {
            this.form.id = response.data.data.id;

            /************* Product Variant Store ******************/
            if (this.productData.variant == "Y") {
              const productVariantFd = new FormData();

              productVariantFd.append("product_id", this.form.id);
              productVariantFd.append(
                "attribute_id",
                this.form.variantForm.attribute_id
              );
              for (var elementId in this.form.variantForm.id) {
                productVariantFd.append(
                  "id[]",
                  this.form.variantForm.id[elementId]
                );
              }

              for (var element in this.form.variantForm.attribute_value_id) {
                productVariantFd.append(
                  "attribute_value_id[]",
                  this.form.variantForm.attribute_value_id[element]
                );
                productVariantFd.append(
                  "price[]",
                  this.form.variantForm.price[element]
                );
                productVariantFd.append(
                  "status[]",
                  this.form.variantForm.status[element]
                );
                productVariantFd.append(
                  "sku[]",
                  this.form.variantForm.sku[element]
                );
              }
              for (var elementImg in this.variantFile.image) {
                productVariantFd.append(
                  "image[]",
                  this.variantFile.image[elementImg]
                );
              }

              ProductVariant.store(productVariantFd);
            }
            /************* End Product Variant Store **************/

            /************* Product Tax Store **********************/
            if (this.form.taxForm) {
              const productTaxFd = new FormData();

              productTaxFd.append("product_id", this.form.id);
              for (var elementTax in this.form.taxForm) {
                productTaxFd.append(elementTax, this.form.taxForm[elementTax]);
              }

              if (this.form.taxForm.id) {
                productTaxFd.append("_method", "PATCH");
              }

              ProductTax.store(productTaxFd, this.form.taxForm.id);
            }
            /************* End Product Tax Store ******************/

            /************* Product Tag Store **********************/
            if (this.form.product_tags) {
              const productTagFd = new FormData();

              productTagFd.append("product_id", this.form.id);
              for (var elementTag in this.form.product_tags) {
                productTagFd.append(
                  "tags[]",
                  this.form.product_tags[elementTag]
                );
              }

              ProductTag.store(productTagFd);
            }
            /************* End Product Tag Store ******************/

            /************* Product Image Store ********************/
            if (this.files.images.length != 0) {
              const productImageFd = new FormData();

              productImageFd.append("product_id", this.form.id);
              for (var elementImage in this.files.images) {
                productImageFd.append(
                  "image[]",
                  this.files.images[elementImage]
                );
              }

              ProductImage.store(productImageFd);
            }
            /************* End Product Image Store ****************/

            /************* Product Category Store *****************/
            const productCategoryFd = new FormData();

            productCategoryFd.append("product_id", this.form.id);
            for (var elementCategory in this.form.product_category) {
              productCategoryFd.append(
                "category_id[]",
                this.form.product_category[elementCategory]
              );
            }
            ProductCategory.store(productCategoryFd);
            /************* End Product Category Store *************/

            this.loading = false;

            this.$router.push({ name: "products" });
          })
          .catch((error) => {
            console.log(error);

            this.loading = false;

            var text = error.response.data.message;
            if (error.response.data.errors) {
              for (var value in error.response.data.errors) {
                if (text) text += "<br>";
                text += error.response.data.errors[value];
              }
            }

            this.$notify({
              title: "Error",
              text: text,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },

  watch: {
    "select.attribute": function () {
      this.form.variantForm.attribute_id = this.select.attribute.id;
      this.attributeValueOption = this.select.attribute.attribute_value;
    },

    "select.attribute_value": function () {
      this.form.variantForm.attribute_value_id = [];
      for (var attribute_value in this.select.attribute_value) {
        this.form.variantForm.attribute_value_id[attribute_value] =
          this.select.attribute_value[attribute_value].id;
      }
    },
  },
};
</script>