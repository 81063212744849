import Api from "./Api";
import Csrf from "./Csrf";

export default {
    async register(form) {
        await Csrf.getCookie();

        return Api.post("/register", form);
    },

    async checkUser(form) {
        await Csrf.getCookie();

        return Api.post("/store-user-check", form);
    },

    async login(form) {
        await Csrf.getCookie();

        return Api.post("/store-user-login", form);
    },

    async logout() {
        await Csrf.getCookie();

        return Api.post("/logout");
    },

    auth() {
        return Api.get("/user");
    }
};
