<template>
    <div class="about pa-10">
        <v-col class="col-md-12">
            <v-card class="mb-5">
                <v-row class="pa-5">
                    <div class="text-left">
                        <v-img
                            v-if="productData.thumbnail_file"
                            width="200"
                            :src="productData.thumbnail_file.url"
                        ></v-img>
                    </div>
                </v-row>
                <div class="pa-5">
                    <h2>{{ productData.product_name }}</h2>
                    <v-row class="mt-5 mb-5 ml-1">
                        <v-card elevation="1">
                            <v-card-text>
                                <v-card-title>2</v-card-title>
                                <v-card-subtitle>Reviews</v-card-subtitle>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="1">
                            <v-card-text>
                                <v-card-title>1</v-card-title>
                                <v-card-subtitle>In wishlist</v-card-subtitle>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="1">
                            <v-card-text>
                                <v-card-title>2</v-card-title>
                                <v-card-subtitle>In cart</v-card-subtitle>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="1">
                            <v-card-text>
                                <v-card-title>12</v-card-title>
                                <v-card-subtitle>Times sold</v-card-subtitle>
                            </v-card-text>
                        </v-card>
                        <v-card elevation="1">
                            <v-card-text>
                                <v-card-title>12 $</v-card-title>
                                <v-card-subtitle>Amount sold</v-card-subtitle>
                            </v-card-text>
                        </v-card>
                    </v-row>
                </div>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-card-text>
                            <v-row class="ma-2">
                                <strong>Status:</strong>
                                &nbsp;
                                <p>{{ productData.product_status }}</p>
                            </v-row>
                            <v-row class="ma-2">
                                <strong>Brand:</strong>
                                &nbsp;
                                <p>{{ productData.brand ? productData.brand.name : "" }}</p>
                            </v-row>
                            <v-row class="ma-2">
                                <strong>Category:</strong>
                                &nbsp;
                                <v-chip
                                    v-for="(value, key) in productData.product_category"
                                    :key="key"
                                    class="ma-2"
                                    color="green"
                                    text-color="white"
                                    small
                                >
                                    {{ value.category.name }}
                                </v-chip>
                            </v-row>
                            <v-row class="ma-2">
                                <strong>Tags:</strong>
                                &nbsp;
                                <v-chip
                                    v-for="(value, key) in productData.product_tags"
                                    :key="key"
                                    class="ma-2"
                                    color="green"
                                    text-color="white"
                                    small
                                >
                                    {{ value.tags }}
                                </v-chip>
                            </v-row>
                            <!-- <v-row class="ma-2">
                            <strong>Warranty:</strong>
                            &nbsp;
                            <p>Published</p>
                          </v-row> -->
                            <v-row class="ma-2">
                                <strong>Mini Purchase Qty:</strong>
                                &nbsp;
                                <p>{{ productData.minimum_purchase }}</p>
                            </v-row>
                            <v-row class="ma-2">
                                <strong>Max Purchase Qty:</strong>
                                &nbsp;
                                <p>{{ productData.maximum_purchase }}</p>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-title>Tax Information</v-card-title>

                        <v-card-text>
                            <v-row class="ma-2">
                                <strong>GST:</strong>
                                &nbsp;
                                <p>
                                    {{
                                        productData.product_tax
                                            ? productData.product_tax.gst +
                                            " " +
                                            productData.product_tax.gst_type
                                            : ""
                                    }}
                                </p>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-divider vertical class="mt-5"></v-divider>
                    <v-col cols="12" md="9">
                        <v-card-title> Product price, stock </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row class="pa-6" v-if="productData.variant == 'N'">
                                <v-col cols="4">
                                    <h4>Price</h4>
                                    <p>55.00€</p>
                                </v-col>
                                <v-col cols="4"
                                ><h4>Stock</h4>
                                    <p>In Stock</p></v-col
                                >
                                <v-col cols="4"
                                ><h4>SKU</h4>
                                    <p></p
                                    ></v-col>
                            </v-row>

                            <v-row
                                class="pa-6"
                                v-else
                                v-for="(variant, key) in productData.product_variant"
                                :key="key"
                            >
                                <v-col cols="3">
                                    <h4>Price</h4>
                                    <p>{{ variant.price }}</p>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Stock</h4>
                                    <p>{{ variant.status }}</p>
                                </v-col>
                                <v-col cols="3">
                                    <h4>SKU</h4>
                                    <p>{{ variant.sku }}</p>
                                </v-col>
                                <v-col cols="3">
                                    <h4>Image</h4>
                                    <v-img
                                        v-if="variant.image_file"
                                        width="50"
                                        height="50"
                                        :src="variant.image_file.url"
                                    ></v-img>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-title> Gallery </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-img
                                    class="ma-5"
                                    v-for="(image, key) in productData.product_image"
                                    :key="key"
                                    width="200"
                                    height="200"
                                    :src="image.image_file.url"
                                ></v-img>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>

                        <v-card-title class="ml-5">Shipping Information</v-card-title>

                        <v-divider></v-divider>

                        <v-container fluid>
                            <v-card-text>
                                <v-row class="ma-2">
                                    <strong>Standard Delivery Time:</strong>
                                    &nbsp;
                                    <p>{{ productData.standard_delivery }}</p>
                                </v-row>
                                <v-row class="ma-2">
                                    <strong>Express Delivery Time:</strong>
                                    &nbsp;
                                    <p>{{ productData.express_delivery }}</p>
                                </v-row>
                                <v-row class="ma-2">
                                    <strong>Weight:</strong>
                                    &nbsp;
                                    <p>{{ productData.weight }}</p>
                                </v-row>
                                <v-row class="ma-2">
                                    <strong>Height:</strong>
                                    &nbsp;
                                    <p>{{ productData.height }}</p>
                                </v-row>
                                <v-row class="ma-2">
                                    <strong>Length:</strong>
                                    &nbsp;
                                    <p>{{ productData.length }}</p>
                                </v-row>
                                <v-row class="ma-2">
                                    <strong>Width:</strong>
                                    &nbsp;
                                    <p>{{ productData.width }}</p>
                                </v-row>
                            </v-card-text>
                        </v-container>
                        <v-divider></v-divider>

                        <v-card-title class="ml-5">Product Description</v-card-title>

                        <v-divider></v-divider>

                        <v-container fluid>
                            <v-row class="ma-4 pb-5" v-html="productData.description">
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>
</template>
<script>
import Product from "@/apis/Product";

export default {
    name: "ProductsView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;

        if (this.$route.params.id) {
            this.product_id = this.$route.params.id;
            this.getProductDetail();
        }
    },

    data: () => ({
        productData: [],
        loading: true,
        product_id: "",
    }),

    methods: {
        getProductDetail: function () {
            Product.show(this.product_id)
                .then((response) => {
                    this.productData = response.data.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },
};
</script>
